import { useDataPage } from "@/pages/data/DataPageContext";
import { getAggregateData, getReportComponentData } from "@/queries/report";
import { useReportContext } from "@/stores/ReportContext";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { buildSingleSeries } from "@/viz/chart-data";
import React, { useEffect } from "react";
import {
  Card,
  MultiAxisChart,
  MultiSeriesChart,
  PercentageChart,
} from "@/pages/data/DataChart";
import { usePage } from "@/components/page/PageContext";

function TotalPaxCount() {
  const { reportId } = useReportContext();
  const { data, isLoading, isSuccess } = getAggregateData(
    [
      {
        query: {
          measure: "pax",
          type: "terminal",
        },
      },
      {
        query: {
          measure: "flight",
          type: "terminal",
        },
      },
      {
        query: {
          measure: "bag",
          type: "terminal",
        },
      },
    ],
    reportId,
  );

  if (!isSuccess) {
    return null;
  }
  const [paxData, flightData, bagData] = data;
  return (
    <Card title="Total Passenger Count">
      <MultiAxisChart
        secondaryAxisSeriesIndex={1}
        secondaryAxisTitle={"Flights"}
        series={[
          {
            name: "Passengers",
            values: paxData.values,
          },
          {
            name: "Flights",
            values: flightData.values,
          },
          {
            name: "Bags",
            values: bagData.values,
          },
        ]}
        labels={paxData.labels}
        xAxisTitle={"Time"}
        yAxisTitle={"Passengers"}
      />
    </Card>
  );
}

function ArrVsDeparting() {
  const { reportId } = useReportContext();

  const { data, isLoading, isSuccess } = getAggregateData(
    [
      {
        query: {
          measure: "pax",
          direction: "arr",
          type: "terminal",
        },
      },
      {
        query: {
          measure: "pax",
          type: "terminal",
        },
      },
    ],
    reportId,
  );

  if (!isSuccess) {
    return null;
  }

  const [arrData, totalData] = data;
  return (
    <Card title="Daily Pax Traffic - Arriving v/s Departing">
      <PercentageChart
        value={arrData.sum}
        total={totalData.sum}
        valueText={"Arriving"}
        remainText={"Departing"}
      />
    </Card>
  );
}

function HourlyDistribution() {
  const { reportId } = useReportContext();

  const { data, isLoading, isSuccess } = getAggregateData(
    [
      {
        query: {
          measure: "pax",
          direction: "arr",
          type: "terminal",
        },
      },
      {
        query: {
          measure: "pax",
          direction: "dep",
          type: "terminal",
        },
      },
      {
        query: {
          measure: "pax",
          type: "terminal",
        },
      },
    ],
    reportId,
  );

  if (!isSuccess) {
    return null;
  }

  const [arrData, depData, totalData] = data;
  return (
    <Card title="Daily Pax Traffic - Arriving v/s Departing">
      <MultiSeriesChart
        chartType="line"
        series={[
          {
            name: "Arriving",
            values: arrData.values,
          },
          {
            name: "Departing",
            values: depData.values,
          },
          {
            name: "Total",
            values: totalData.values,
          },
        ]}
        labels={arrData.labels}
        yAxisTitle={"Passengers"}
        xAxisTitle={"Time"}
      />
    </Card>
  );
}

export default function PaxOverviewPage() {
  const { setTitles } = usePage();
  useEffect(() => {
    setTitles(["Passenger Overview"]);
  }, []);
  return (
    <div>
      <div className="w-full h-90">
        <TotalPaxCount />
      </div>
      <div className="w-full flex h-90 space-x-4">
        <div className="w-2/5">
          <ArrVsDeparting />
        </div>
        <div className="w-3/5">
          <HourlyDistribution />
        </div>
      </div>
    </div>
  );
}
