import React from "react";
import { usePage } from "@/components/page/PageContext";
import {
  ProfileMenu,
  ReportRefresh,
  SessionSwitcher,
} from "@/components/header/header-utils";
import { SidebarTrigger } from "@/components/ui/sidebar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function HeaderBar3() {
  const { titles } = usePage();

  return (
    <div className="w-full bg-gray-800 flex p-2 lg:p-4 items-center">
      <SidebarTrigger />
      <div className="flex-grow">
        {titles.length > 0 && (
          <h1 className="text-lg lg:text-2xl text-slate-300 ml-2 lg:ml-6">
            {titles[titles.length - 1]}
          </h1>
        )}
      </div>
      <div>
        <ReportRefresh />
      </div>
      <div>
        <SessionSwitcher />
      </div>
      <div>
        <ProfileMenu />
      </div>
    </div>
  );
}
