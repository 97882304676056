import {
  formatDistance,
  format,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Activity, getAuditForPlay, Play } from "@/queries/plays";
import React from "react";
import { Skeleton } from "@/components/ui/skeleton";

const isWithinLastDay = (date: Date): boolean => {
  return differenceInHours(new Date(), date) < 24;
};

const EVENT_MESSAGES = {
  created: "created the play",
  "action.complete": "completed an execute",
  "action.all": "completed all execute actions",
  "mark.scheduled": "marked play as scheduled",
  "mark.actioned": "marked play as executed",
  "mark.completed": "marked play as completed",
  "auto.resolved": "automatically resolved the play",
  comment: "commented",
};

function ActivityItem({ activity }: { activity: Activity }) {
  const actorName = activity.actor?.name ?? "RaidoHub System";

  // Generate message based on action and action_name
  const message =
    activity.action_name && activity.action === "action.complete"
      ? `executed - `
      : EVENT_MESSAGES[activity.action as keyof typeof EVENT_MESSAGES];

  const timestamp = new Date(activity.created_at);
  const timeString =
    differenceInDays(new Date(), timestamp) < 1
      ? formatDistance(timestamp, new Date(), { addSuffix: true })
      : format(timestamp, "HH:mm MM/dd");

  return (
    <div className="space-y-1 pb-4 last:pb-0">
      <div className="flex items-center justify-between gap-4">
        <p className="text-sm min-w-0">
          <span className="font-medium text-slate-200">{actorName}</span>{" "}
          <span className="text-slate-300">
            {message}
            {activity.action_name && activity.action === "action.complete" && (
              <span
                className="truncate inline-block max-w-[300px] align-bottom"
                title={activity.action_name}
              >
                {activity.action_name}
              </span>
            )}
          </span>
        </p>
        <time className="text-xs text-slate-400 flex-shrink-0">
          {timeString}
        </time>
      </div>

      {activity.comment && (
        <div className="mt-2 rounded bg-slate-800 p-3 text-sm text-slate-300">
          {activity.comment}
        </div>
      )}
    </div>
  );
}

export function PlayActivity({ play }: { play: Play }) {
  const { data: activities, isLoading } = getAuditForPlay(play.id);

  if (isLoading) {
    return (
      <div>
        <h3 className="text-lg font-semibold mb-2">Activity Log</h3>
        <Skeleton className="w-full h-32 bg-slate-700" />
      </div>
    );
  }

  if (!activities?.length) {
    return (
      <div>
        <h3 className="text-lg font-semibold mb-2">Activity Log</h3>
        <p className="text-slate-300">No activity recorded for this play.</p>
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Activity Log</h3>
      <div className="space-y-4 mt-4">
        {activities.map((activity) => (
          <ActivityItem key={activity.id} activity={activity} />
        ))}
      </div>
    </div>
  );
}
