import React, { useEffect } from "react";
import { MetricDisplay } from "@/components/retail/RetailViz";
import { getRetailReport, RetailReport } from "@/queries/retail";
import { useReportContext } from "@/stores/ReportContext";
import { ExploreContext, ZoneData } from "@/components/explore/ExploreContext";
import { trimZones } from "@/components/explore/ExploreView";
import { useSessionData } from "@/stores/SessionDataContext";
import { TableView } from "@/components/explore/ExploreTable";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { buildMultiAxis } from "@/viz/chart-data";

function Card({ title, children, wrapperClassName = "", innerClassName = "" }) {
  return (
    <div
      className={`bg-slate-900 border-slate-600 border drop-shadow flex flex-col mt-4 h-90 md:h-auto ${wrapperClassName}`}
    >
      <div className="px-4 py-4 text-slate-300 border-b border-slate-700">
        {title}
      </div>
      <div className={`p-4 ${innerClassName}`}>{children}</div>
    </div>
  );
}

function convertTo15MinIntervalsAndSort(
  gates: ZoneData[] | null,
): ZoneData[] | null {
  if (!gates) return null;

  const convertedGates = gates.map((gate) => {
    const newValues: number[] = [];
    const newNotes: (string | null)[] = [];

    for (let i = 0; i < gate.values.length; i += 3) {
      if (i + 2 < gate.values.length) {
        // Full 30-minute block
        newValues.push(gate.values[i]);
        newValues.push(
          Math.round((gate.values[i + 1] + gate.values[i + 2]) / 2),
        );

        newNotes.push(gate.notes[i]);
        newNotes.push(gate.notes[i + 1] || gate.notes[i + 2] || null);
      } else if (i + 1 < gate.values.length) {
        // Last incomplete block (20 minutes)
        newValues.push(gate.values[i]);
        newValues.push(Math.round(gate.values[i + 1] / 2));

        newNotes.push(gate.notes[i]);
        newNotes.push(gate.notes[i + 1] || null);
      } else {
        // Last single 10-minute interval
        newValues.push(gate.values[i]);
        newNotes.push(gate.notes[i]);
      }
    }

    return {
      ...gate,
      values: newValues,
      notes: newNotes,
    };
  });

  // Sort the converted gates by name
  return convertedGates.sort((a, b) => a.name.localeCompare(b.name));
}

function GateDisplay({ summary }: { summary: RetailReport }) {
  const { airport_config } = useSessionData();

  const { zones, times } = trimZones(
    convertTo15MinIntervalsAndSort(summary.gates),
    airport_config.start_hour,
    airport_config.end_hour,
    15,
  );

  return (
    <Card title={"Relevant Gates"} innerClassName={""}>
      <ExploreContext.Provider
        value={{
          zones,
          times,
          concerns: [],
        }}
      >
        <TableView />
      </ExploreContext.Provider>
    </Card>
  );
}

function ChartDisplay({ summary }: { summary: RetailReport }) {
  return (
    <Card title="Traffic Overview">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildMultiAxis({
          height: 400,
          data: summary.chart_hourly_breakdown,
          xAxisTitle: "Time",
          secondaryAxisSeriesIndex: 1,
        })}
      />
    </Card>
  );
}

const SingleStoreReport: React.FC<{ summary: RetailReport }> = ({
  summary,
}) => {
  const isRenderable = (value: any): boolean => {
    return value !== null && value !== undefined;
  };

  function displayStatus(status) {
    // Define a mapping of statuses to Tailwind CSS classes and text
    const statusMap = {
      OnTime: { color: "", text: "On Time" },
      Early: { color: "text-green-800", text: "Early" },
      Delayed: { color: "text-red-800", text: "Delayed" },
    };

    // Get the status configuration or use the default
    const { color, text } = statusMap[status] || { color: "", text: status };

    // Return a React node
    return <span className={color}>{text}</span>;
  }

  const { code_iata } = useSessionData();

  return (
    <div className={"w-full"}>
      <div className="flex w-full">
        <div className="w-full">
          <Card
            title="Last Flights of the Day"
            wrapperClassName="w-full h-full !mt-0 "
          >
            <table className="flight-table !mt-0 w-full">
              <thead>
                <tr>
                  <th>Gate</th>
                  <th>Flight</th>
                  <th>Scheduled</th>
                  <th>Estimated</th>
                  <th>Delay</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {summary.takeoffs.map((flight) => (
                  <tr key={flight.gate_name}>
                    <td>
                      {flight.gate_guess_flag ? (
                        <span className="opacity-70">*{flight.gate_name}</span>
                      ) : (
                        <span>{flight.gate_name}</span>
                      )}
                    </td>
                    <td>{flight.flight_name}</td>
                    <td>{flight.sch_dep_time}</td>
                    <td>{flight.dep_time}</td>
                    <td>{flight.delay}</td>
                    <td>{displayStatus(flight.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {summary.takeoffs.some((flight) => flight.gate_guess_flag) && (
              <div className="opacity-50 font-mono text-xs mt-4">
                Gates marked with "*" show temporary assignments based on
                historical data. These will be updated once final gates are
                confirmed.
              </div>
            )}
          </Card>
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <MetricDisplay
          name={
            summary.primary_terminal
              ? `Total Passengers @ Terminal ${summary.primary_terminal}`
              : `Total Passengers @ ${code_iata}`
          }
          value={summary.total_pax}
        />
        <MetricDisplay
          name={
            summary.primary_terminal
              ? `Total Flights @ Terminal ${summary.primary_terminal}`
              : `Total Flights @ ${code_iata}`
          }
          value={summary.total_flights}
        />
        <MetricDisplay
          name={"Flights in Service Area"}
          value={summary.service_flights}
        />
      </div>
      <div className="flex space-x-4 mt-4">
        <MetricDisplay
          name={"Remaining Departures"}
          value={summary.remain_dep_flights}
        />
        <MetricDisplay
          name={"Remaining DEP Passengers"}
          value={summary.remaining_pax_dep}
        />
        <MetricDisplay
          name={"Total Pax Remaining"}
          value={summary.remain_pax}
        />
      </div>
      <div className="flex space-x-4 mt-4">
        {isRenderable(summary.hour_flights) && (
          <MetricDisplay
            name={"Flights Next Hour"}
            value={summary.hour_flights}
          />
        )}
        {isRenderable(summary.hour_pax) && (
          <MetricDisplay name={"Pax Next Hour"} value={summary.hour_pax} />
        )}
        {isRenderable(summary.current_pax) && (
          <MetricDisplay
            name={"Current Pax in Service Area"}
            value={summary.current_pax}
          />
        )}
      </div>

      <div className={"w-full highcharts-dark"}>
        <ChartDisplay summary={summary} />
      </div>
      {summary.gates && <GateDisplay summary={summary} />}
    </div>
  );
};

const AllStoresReport: React.FC<{ summary: RetailReport }> = ({ summary }) => {
  const isRenderable = (value: any): boolean => {
    return value !== null && value !== undefined;
  };

  const { code_iata } = useSessionData();

  return (
    <div className={"w-full"}>
      <div className="flex space-x-4">
        <MetricDisplay
          name={`Total Passengers @ ${code_iata}`}
          value={summary.total_pax}
        />
        <MetricDisplay
          name={`Total Flights @ ${code_iata}`}
          value={summary.total_flights}
        />
        <MetricDisplay
          name={"Flights in Service Area"}
          value={summary.service_flights}
        />
      </div>
      <div className="flex space-x-4 mt-4">
        <MetricDisplay
          name={"Remaining Departures"}
          value={summary.remain_dep_flights}
        />
        <MetricDisplay
          name={"Remaining DEP Passengers"}
          value={summary.remaining_pax_dep}
        />
        <MetricDisplay
          name={"Total Pax Remaining"}
          value={summary.remain_pax}
        />
      </div>
      <div className={"w-full highcharts-dark"}>
        <ChartDisplay summary={summary} />
      </div>
    </div>
  );
};

function RetailSummary({ stores = [] }: { stores: number[] }) {
  const { reportId } = useReportContext();
  const { restrict_store } = useSessionData();
  const singleStore = !!restrict_store;
  const { data: summary } = getRetailReport({
    report: reportId,
    stores,
  });

  useEffect(() => {
    if (summary) {
      const storeNames =
        stores.length === 1 ? `Store ${stores[0]}` : "All Stores";
      document.title = `Retail Summary | ${storeNames}`;
    }
  }, [summary, stores]);

  if (!summary) {
    return null;
  }

  if (stores.length === 1 || singleStore) {
    return <SingleStoreReport summary={summary} />;
  } else {
    return <AllStoresReport summary={summary} />;
  }
}

export default RetailSummary;
