// api.ts
import axios from "axios";
import { API_ENDPOINT } from "@/lib/constants";

// @ts-ignore
const myaxios = axios.create({
  baseURL: `/api/`, // @ts-ignore
  headers: {
    "Content-Type": "application/json",
  },
});

// myaxios.interceptors.request.use(config => {
//   const token = localStorage.getItem('accessToken');
//   if (token) {
//     config.headers['Authorization'] = 'Bearer ' + token;
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

export default myaxios;
