import { FunctionComponent, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute: FunctionComponent = () => {
  const isSuccess = true;

  return isSuccess ? <Outlet /> : null;
};

export default ProtectedRoute;
