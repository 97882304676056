import React, { useEffect } from "react";
import dayjs from "dayjs";
import { getAllReports, Report } from "@/queries/report";

interface Link {
  date: string;
  link: string;
  color?: string;
}

interface CalendarProps {
  month: number;
  year: number;
  links: Link[];
}

const Calendar: React.FC<CalendarProps> = ({ month, year, links }) => {
  const firstDayOfMonth = dayjs(`${year}-${month}-01`);
  const daysInMonth = firstDayOfMonth.daysInMonth();
  const startDay = firstDayOfMonth.day(); // 0 (Sunday) - 6 (Saturday)

  // Create an array with the days of the week (starting with Sunday)
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Create an array for the days in the month
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const linksMap = links.reduce<Record<number, string>>(
    (acc, { date, link }) => {
      const day = dayjs(date).date();
      acc[day] = link;
      return acc;
    },
    {},
  );

  return (
    <div className="flex flex-col items-center">
      {/* Days of the week */}
      <div className="grid grid-cols-7 w-full">
        {daysOfWeek.map((day) => (
          <div key={day} className="flex justify-center py-2 font-bold">
            {day}
          </div>
        ))}
      </div>

      {/* Days of the month */}
      <div className="grid grid-cols-7 w-full gap-y-2">
        {/* Empty slots for the days of the previous month */}
        {Array.from({ length: startDay }).map((_, i) => (
          <div key={`empty-${i}`} />
        ))}

        {daysArray.map((day) => (
          <div key={day} className="flex justify-center py-2">
            {linksMap[day] ? (
              <a href={linksMap[day]} className="text-blue-500 underline">
                {day}
              </a>
            ) : (
              <span>{day}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// Function to build calendars for all months with reports
const buildCalendars = (reports: Report[]) => {
  const validReports = reports.filter((report) => report.target_date !== null);

  // Group reports by month and year
  const groupedReports: Record<string, Report[]> = validReports.reduce(
    (acc, report) => {
      const date = dayjs(report.target_date);
      const key = `${date.year()}-${date.month() + 1}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(report);
      return acc;
    },
    {},
  );

  const MonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Generate Calendar components for each month with reports
  return Object.entries(groupedReports).map(([key, reportsForMonth]) => {
    const [year, month] = key.split("-").map(Number);

    const links: Link[] = reportsForMonth.map((report) => ({
      date: report.target_date,
      link: `/?report_id=${report.id}`,
    }));

    return (
      <div key={key} className={"w-1/3"}>
        <h2 className="text-center text-xl pb-4">
          Forecast for {MonthNames[month - 1]} {year}
        </h2>
        <Calendar month={month} year={year} links={links} />
      </div>
    );
  });
};

const ForecastCalendar: React.FC = () => {
  const { data: reports, isLoading } = getAllReports();
  if (isLoading) {
    return null;
  }

  const calendars = buildCalendars(reports);

  return <div className="p-4 flex">{calendars}</div>;
};

export default ForecastCalendar;
