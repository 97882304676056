import React, { useState, ReactNode } from "react";

// Define the shape of the context
interface DataPageContextType {
  title: string;
  setTitle: (newTitle: string) => void;
}

// Create the context with a default value
const DataPageContext = React.createContext<DataPageContextType | undefined>(
  undefined,
);

// Create a provider component
interface DataPageProviderProps {
  children: ReactNode;
  initialTitle: string;
}

export const DataPageProvider: React.FC<DataPageProviderProps> = ({
  children,
  initialTitle,
}) => {
  const [title, setTitle] = useState(initialTitle);

  return (
    <DataPageContext.Provider value={{ title, setTitle }}>
      {children}
    </DataPageContext.Provider>
  );
};

// Create a custom hook to use the context
export const useDataPage = (): DataPageContextType => {
  const context = React.useContext(DataPageContext);
  if (context === undefined) {
    throw new Error("useDataPage must be used within a DataPageProvider");
  }
  return context;
};
