// React hook to extract terminal id from url, and convert to integer
import { useParams } from "react-router-dom";
import { components } from "@/lib/openapi";
import { useSessionData } from "@/stores/SessionDataContext";

export type TerminalSetup = components["schemas"]["TerminalSetup"];
export function useTerminal(): TerminalSetup | null {
  try {
    // Check if params exist
    const { terminal } = useParams<{ terminal: string }>();

    // Early return if terminal param is missing or empty
    if (!terminal || typeof terminal !== "string") {
      return null;
    }

    // Get airport data with null check
    const { airport } = useSessionData();

    // Check if airport and terminal array exist
    if (!airport?.terminal || !Array.isArray(airport.terminal)) {
      return null;
    }

    // Find terminal with type guard
    const foundTerminal = airport.terminal.find(
      (term): term is TerminalSetup =>
        term !== null &&
        term !== undefined &&
        typeof term.name === "string" &&
        term.name === terminal,
    );

    return foundTerminal || null;
  } catch (error) {
    console.error("Error in useTerminal:", error);
    return null;
  }
}

export function useTerminalId(): number | null {
  const terminal = useTerminal();
  if (!terminal) {
    return null;
  }

  return terminal.id;
}
