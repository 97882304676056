import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { useSessionData } from "@/stores/SessionDataContext";
import { ExploreView } from "@/components/explore/ExploreView";
import { useReportContext } from "@/stores/ReportContext";
import { ExploreConfig } from "@/types/explore";
import { getWaitTimeData, WaitTimeRequestSchema } from "@/queries/report";
import { Button } from "@/components/ui/button";

function ChartWrapper({ options, constructorType = "chart" }) {
  return (
    <div className="w-full h-full highcharts-dark">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={constructorType}
        options={options}
      />
    </div>
  );
}

interface ChartProps {
  width?: number;
  height?: number;
}

export interface Series {
  values: number[];
  name: string;
}

interface IMultiSeriesChart extends ChartProps {
  series: Series[];
  labels: string[];
  xAxisTitle: string;
  yAxisTitle: string;
}

interface ISingleSeriesChart extends ChartProps {
  values: number[];
  labels: string[];
  xAxisTitle: string;
  yAxisTitle: string;
}

interface MultiAxisChartProps extends IMultiSeriesChart {
  secondaryAxisSeriesIndex: number;
  secondaryAxisTitle: string;
}

interface CardProps {
  title: string;
  children: React.ReactNode;
  wrapperClassName?: string;
  innerClassName?: string;
  actions?: React.ReactNode;
}

export function Card({
  title,
  children,
  wrapperClassName = "",
  innerClassName = "",
  actions,
}: CardProps) {
  return (
    <div
      className={`bg-slate-900 border-slate-600 border drop-shadow flex flex-col mt-4 h-full w-full ${wrapperClassName}`}
    >
      <div className="px-4 py-4 text-slate-300 border-b border-slate-700 flex justify-between items-center ">
        <div className="flex-grow">{title}</div>
        {actions && <div className="ml-4">{actions}</div>}
      </div>
      <div className={`p-4 ${innerClassName}`}>{children}</div>
    </div>
  );
}

export function MultiAxisChart(props: MultiAxisChartProps) {
  const options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },

    chart: {
      type: "column",
      styledMode: true,
      // width: props.width,
      // height: props.height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: props.labels,
      title: {
        text: props.xAxisTitle,
      },
    },

    yAxis: props.series.map((series, index) => ({
      visible: index === 0 || index === props.secondaryAxisSeriesIndex,
      title: {
        text: series.name,
      },
      opposite: index === props.secondaryAxisSeriesIndex,
    })),
    series: props.series.map((series, index) => ({
      name: series.name,
      type: "column",
      yAxis: index,
      data: series.values,
    })),
  };
  return <ChartWrapper options={options} />;
}

interface PercentageChartProps extends ChartProps {
  value: number;
  total: number;
  valueText: string;
  remainText: string;
}

export function PercentageChart(props: PercentageChartProps) {
  const percent = Math.floor((props.value * 100) / props.total);
  const remain = props.total - props.value;
  const options = {
    credits: {
      enabled: false,
    },

    chart: {
      styledMode: true,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      // width,
      // height,
    },
    title: {
      text: `${percent}%`,
      align: "center",
      verticalAlign: "middle",
      y: 75,
    },
    subtitle: {
      text: props.valueText,
      align: "center",
      verticalAlign: "middle",
      y: 90,
    },
    tooltip: {
      pointFormat: "{point.y} <br/> <b>({point.percentage:.1f}%)</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          // distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "80%",
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        innerSize: "50%",
        data: [
          [props.valueText, props.value],
          [props.remainText, remain],
        ],
      },
    ],
  };
  return <ChartWrapper options={options} />;
}

interface MultiSeriesChartProps extends IMultiSeriesChart {
  chartType: "line" | "column";
  totalRequired?: boolean;
  stacked?: boolean;
}

export function MultiSeriesChart(props: MultiSeriesChartProps) {
  const totalRequired = !!props.totalRequired;
  const stacked = !!props.stacked;

  const totalSeriesData = totalRequired
    ? [
        {
          name: "Total",
          type: "line", // @ts-ignore
          yAxis: 1,
          data: props.labels.map((_, idx) => {
            return props.series.reduce(
              (sum, series) => sum + series.values[idx],
              0,
            );
          }),
        },
      ]
    : [];

  const secondYAxis = !!props.totalRequired
    ? [
        {
          // Secondary yAxis for line
          title: {
            text: "Total",
          },
          opposite: true,
        },
      ]
    : [];

  let options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },

    chart: {
      type: stacked ? "column" : props.chartType,
      styledMode: true,
      // width: width,
      // height: height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: props.yAxisTitle,
        },
      },
    ].concat(secondYAxis),

    xAxis: {
      categories: props.labels,
      title: {
        text: props.xAxisTitle,
      },
    },
    series: props.series
      .map((s) => {
        return {
          name: s.name,
          data: s.values,
        };
      })
      .concat(totalSeriesData),
  };

  if (stacked) {
    options.plotOptions["column"] = {
      stacking: "stacked",
      dataLabels: {
        enabled: false,
      },
    };
  }

  return <ChartWrapper options={options} />;
}

export function ExploreDisplay({
  query,
  height = 400,
}: {
  query: ExploreConfig;
  height?: number;
}) {
  return <ExploreView config={query} heatMapHeight={height} />;
}

interface SingleSeriesChartProps extends ISingleSeriesChart {
  type: string;
}

export function SingleSeriesChart(props: SingleSeriesChartProps) {
  const options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },

    chart: {
      type: props.type,
      styledMode: true,
      // width: width,
      // height: height,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: props.labels,
      title: {
        text: props.xAxisTitle,
      },
    },
    yAxis: {
      title: {
        text: props.yAxisTitle,
      },
    },
    series: [
      {
        name: "",
        data: props.values,
      },
    ],
  };

  return <ChartWrapper options={options} />;
}

interface TimeSeriesChart extends ChartProps {
  data: [number, number][];
  yAxisTitle: string;
  seriesLabel: string;
}

export function TimeSeriesChart(props: TimeSeriesChart) {
  const options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },

    chart: {
      type: "line",
      styledMode: true,
      // width: width,
      // height: height,
      zooming: {
        type: "x",
      },
    },
    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      // categories: props.labels,
      // title: {
      //   text: props.xAxisTitle,
      // },
    },
    yAxis: {
      title: {
        text: props.yAxisTitle,
      },
    },
    tooltip: {
      valueDecimals: 2,
    },
    series: [
      {
        name: props.seriesLabel,
        data: props.data,
      },
    ],
  };

  return <ChartWrapper options={options} />;
}

interface DonutChartProps extends ChartProps {
  // each index should correspond to the same slice
  values: number[];
  labels: number[];
  centerLabel?: string; // only used when no radii set
  centerLabelSubText?: string; // only used when no radii set
  radii?: number[]; // customize individual radius of each slice
}

export function DonutChart(props: DonutChartProps) {
  const { values, labels, centerLabel, centerLabelSubText, radii } = props;

  const getData = () => {
    return labels.map((label, index) => ({
      name: label,
      y: values[index],
      ...(radii ? { z: radii[index] } : {}),
    }));
  };

  const options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    chart: {
      type: radii ? "variablepie" : "pie",
      styledMode: true,
      events:
        !radii && centerLabel
          ? {
              render: function () {
                const chart = this;
                const series = chart.series[0];
                let customLabel = chart.customLabel;

                if (!customLabel) {
                  customLabel = chart.customLabel = chart.renderer
                    .label(
                      `${centerLabel}<br/>${centerLabelSubText ? `<strong>${centerLabelSubText}</strong>` : ""}`,
                    )
                    .css({
                      color: "#eee",
                      textAlign: "center",
                      textAnchor: "middle",
                    })
                    .add();
                }

                const x = series.center[0] + chart.plotLeft;
                const y =
                  series.center[1] +
                  chart.plotTop -
                  customLabel.attr("height") / 2;

                customLabel.attr({ x, y });
                customLabel.css({
                  fontSize: `${series.center[2] / 12}px`,
                });
              },
            }
          : undefined,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        borderRadius: 8,
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            format: "{point.name}",
          },
          {
            enabled: true,
            distance: -15,
            format: "{point.percentage:.0f}%",
            style: {
              fontSize: "0.9em",
            },
          },
        ],
      },
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        innerSize: "75%",
        ...(radii && {
          minPointSize: 10,
          zMin: 0,
        }),
        data: getData(),
      },
    ],
  };

  return <ChartWrapper options={options} />;
}

export function WaitTimeChart({
  config,
  title = "Wait Time",
}: {
  config: WaitTimeRequestSchema;
  title?: string;
}) {
  return null;
  const { reportId } = useReportContext();
  const [showOverrideUI, setShowOverrideUI] = useState(false);
  const [override, setOverride] = useState(false);
  const [scheduleStr, setScheduleStr] = useState("");
  const [rateStr, setRateStr] = useState("");
  const { is_staff } = useSessionData();
  const [overrideValues, setOverrideValues] = useState<{
    override_schedule: number[];
    override_rate: number;
  } | null>(null);

  const { data: wtData, isSuccess: wtSuccess } = getWaitTimeData(
    {
      ...config,
      ...(override && overrideValues),
    },
    reportId,
    true,
  );

  useEffect(() => {
    if (wtData && !overrideValues) {
      setScheduleStr(wtData.schedule?.join(", ") || "");
      setRateStr(wtData.rate?.toString() || "2");
      setOverrideValues({
        override_schedule: wtData.schedule || [],
        override_rate: wtData.rate || 2,
      });
    }
  }, [wtData]);

  const handleScheduleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleStr(e.target.value);
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRateStr(e.target.value);
  };

  const handleApplyOverrides = () => {
    const schedule = scheduleStr
      .split(",")
      .map((v) => parseInt(v.trim()))
      .filter((v) => !isNaN(v));
    const rate = parseFloat(rateStr);
    setOverride(true);
    if (!isNaN(rate)) {
      setOverrideValues({
        override_schedule: schedule,
        override_rate: rate,
      });
    }
  };

  const handleOverrideToggle = () => {
    if (!showOverrideUI) {
      setScheduleStr(wtData?.schedule?.join(", ") || "");
      setRateStr(wtData?.rate?.toString() || "2");
    }
    setShowOverrideUI(!showOverrideUI);
  };

  const actions = is_staff ? (
    <Button size="sm" variant="ghost" onClick={handleOverrideToggle}>
      Override
    </Button>
  ) : null;

  return (
    <Card title={title} actions={actions}>
      {showOverrideUI && overrideValues && (
        <div className="flex flex-col gap-4 mb-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm text-slate-400">
              Schedule (comma separated)
            </label>
            <input
              type="text"
              className="bg-slate-800 border border-slate-600 rounded px-2 py-1"
              value={scheduleStr}
              onChange={handleScheduleChange}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm text-slate-400">Per Pax Sec</label>
            <input
              type="text"
              className="bg-slate-800 border border-slate-600 rounded px-2 py-1"
              value={rateStr}
              onChange={handleRateChange}
            />
          </div>
          <Button size="sm" onClick={handleApplyOverrides}>
            Apply Changes
          </Button>
        </div>
      )}
      {wtSuccess && (
        <TimeSeriesChart
          data={wtData.data}
          yAxisTitle={"Wait Time (min)"}
          seriesLabel={""}
        />
      )}
    </Card>
  );
}
