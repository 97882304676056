import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useForgotPassword } from "@/queries/user";
import Cookies from "js-cookie";

interface LocationState {
  from: {
    pathname: string;
  };
}

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const [successMessage, setSuccessMessage] = useState<undefined | string>(
    undefined,
  );

  const { mutateAsync: doForgotPassword } = useForgotPassword();

  const handleRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(undefined);
    //
    try {
      const { success, message } = await doForgotPassword({
        email,
      });

      if (success) {
        setSuccessMessage(message ? message : "Please check your email");
      } else if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl">Forgot Password</CardTitle>
          <CardDescription>
            Please enter an email to reset your password.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {errorMessage && (
            <div className="opacity-70 dark:bg-orange-900 border dark:border-orange-600 rounded px-4 py-2 mb-4 text-sm">
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="opacity-70 dark:bg-green-900 border dark:border-green-600 rounded px-4 py-2 mb-4 text-sm">
              {successMessage}
            </div>
          )}

          <div className="space-y-2">
            <Label htmlFor="username">Email</Label>
            <Input
              id="email"
              placeholder=""
              required
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full" onClick={handleRequest}>
            Continue
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ForgotPasswordPage;
