import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";

interface CommentFormProps {
  onSuccess: (comment: string) => void;
  onCancel: () => void;
  buttonLabel?: string;
}

export function CommentForm({
  onSuccess,
  onCancel,
  buttonLabel = "Submit",
}: CommentFormProps) {
  const [comment, setComment] = useState("");

  return (
    <div className="mt-2 space-y-2">
      <Textarea
        placeholder="Comment (optional)"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="bg-slate-700 text-white border-slate-600 min-h-[64px] max-h-96 resize-y"
        rows={3}
      />
      <div className="flex justify-between items-center">
        <Button onClick={() => onSuccess(comment)}>{buttonLabel}</Button>
        <button
          onClick={onCancel}
          className="text-sm text-slate-400 hover:text-slate-300"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export const formatInterval = (index: number) => {
  // Calculate hours and minutes
  const totalMinutes = index * 10;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Format with leading zeros
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};
