import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { AlertTriangle, CircleX, MessageSquare } from "lucide-react";
import { format, formatDistanceToNow } from "date-fns";
import { PlayInformation } from "@/components/playlist/detail/PlayInformation";
import { PlayStatus } from "@/components/playlist/detail/PlayStatus";
import { PlayActions } from "@/components/playlist/detail/PlayActions";
import {
  Action,
  getActionsForPlay,
  getAuditForPlay,
  getPlay,
  Play,
} from "@/queries/plays";
import { PlayActivity } from "@/components/playlist/detail/PlayActivity";

type PlayDetailProps = {
  playId: number;
  onClose?: () => void;
};

// Main PlayDetail component
export function PlayDetail({ playId, onClose }: PlayDetailProps) {
  const { data: play, isLoading } = getPlay(playId);
  return (
    <div className="">
      <Card className="w-full  mx-auto text-white">
        {isLoading && <Skeleton className="w-full h-64" />}
        {play && (
          <>
            <PlayInformation play={play} onClose={onClose} />
            <CardContent className="space-y-6">
              <PlayStatus play={play} />
              <PlayActions play={play} />
              <PlayActivity play={play} />
            </CardContent>
          </>
        )}
      </Card>
    </div>
  );
}
