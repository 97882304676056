import { client } from "@/queries/client";
import { useQuery } from "@tanstack/react-query";
import { components } from "@/lib/openapi";

export type RetailReport = components["schemas"]["RetailReport"];

export function getRetailReport({
  report,
  stores = [],
}: {
  report: number;
  stores: number[];
}) {
  return useQuery({
    queryKey: ["retail_summary", report, stores],
    queryFn: async ({ signal }) => {
      // @ts-ignore
      const { data, error } = await client
        .get()
        .POST("/api/retail/summary/{report_id}", {
          signal,
          params: {
            path: {
              report_id: report,
            },
          },
          body: {
            stores,
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}
