import React, { useEffect, useState } from "react";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDown } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useReportContext } from "@/stores/ReportContext";

type IconType = React.ComponentType<{ className?: string }>;

export interface SubItemProps {
  title: string;
  url: string;
  icon?: IconType;
}

export interface ItemProps {
  title: string;
  url?: string;
  icon?: IconType;
  subItems?: SubItemProps[];
}

interface GroupMenuProps {
  title: string;
  items: ItemProps[];
  defaultOpen?: boolean;
}

export const GroupMenu: React.FC<GroupMenuProps> = ({
  title,
  items,
  defaultOpen = false,
}) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { forced, reportId } = useReportContext();

  const stripQueryParams = (url: string) => {
    return url.split("?")[0];
  };

  const appendReportId = (url: string) => {
    if (forced && reportId) {
      const separator = url.includes("?") ? "&" : "?";
      return `${url}${separator}report_id=${reportId}`;
    }
    return url;
  };

  const isSubItemActive = (item: ItemProps) => {
    if (!item.subItems) return false;
    const currentPath = location.pathname;
    return item.subItems.some(
      (subItem) => subItem.url && stripQueryParams(subItem.url) === currentPath,
    );
  };

  useEffect(() => {
    setActiveItem(null);
    const currentPath = location.pathname;
    items.forEach((item, index) => {
      if (item.url && stripQueryParams(item.url) === currentPath) {
        setActiveItem(`${index}`);
      } else if (item.subItems) {
        item.subItems.forEach((subItem, subIndex) => {
          if (subItem.url && stripQueryParams(subItem.url) === currentPath) {
            setActiveItem(`${index}-${subIndex}`);
          }
        });
      }
    });
  }, [location.pathname, items]);

  return (
    <SidebarGroup>
      <SidebarGroupLabel>{title}</SidebarGroupLabel>
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {item.subItems ? (
                <Collapsible
                  defaultOpen={defaultOpen || isSubItemActive(item)}
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton>
                        {item.icon && <item.icon className="mr-0" />}
                        {item.title}
                        <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.subItems.map((subItem, subIndex) => (
                          <SidebarMenuSubItem key={subIndex}>
                            <SidebarMenuSubButton
                              asChild
                              isActive={activeItem === `${index}-${subIndex}`}
                            >
                              <Link to={appendReportId(subItem.url || "#")}>
                                {subItem.icon && (
                                  <subItem.icon className="mr-0" />
                                )}
                                {subItem.title}
                              </Link>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              ) : (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    asChild
                    isActive={activeItem === `${index}`}
                  >
                    <Link to={appendReportId(item.url || "#")}>
                      {item.icon && <item.icon className="mr-0" />}
                      {item.title}
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
            </React.Fragment>
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
};
