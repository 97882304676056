import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { CircleX } from "lucide-react";
import React from "react";
import { formatInterval } from "@/components/playlist/detail/Common";
import { Play } from "@/queries/plays";

const StatusIndicator = ({
  label,
  timestamp,
}: {
  label: string;
  timestamp: string | null;
}) => (
  <div className="flex items-center space-x-1">
    <div
      className={`w-3 h-3 rounded-full border ${timestamp ? "bg-green-500" : "bg-gray-300"}`}
    />
    <span className="text-xs font-mono">{label}</span>
  </div>
);

// PlayInformation.tsx
interface PlayInformationProps {
  play: Play;
  onClose?: () => void;
}

export function PlayInformation({ play, onClose }: PlayInformationProps) {
  return (
    <>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CardTitle className="text-lg">{play.title}</CardTitle>
            {play.read_only && (
              <span className="px-2 py-1 text-xs font-semibold rounded bg-slate-700 text-slate-300">
                Archived
              </span>
            )}
          </div>
          {onClose && (
            <Button variant="ghost" onClick={onClose}>
              <CircleX />
            </Button>
          )}
        </div>
        <CardDescription className="text-slate-300">
          {play.message}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex space-x-4">
          <StatusIndicator label="Schedule" timestamp={play.scheduled_at} />
          <StatusIndicator label="Execute" timestamp={play.actioned_at} />
          <StatusIndicator label="Complete" timestamp={play.completed_at} />
        </div>
        <div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="font-semibold text-slate-300">Location:</p>
              <p className="text-slate-300">
                {play.location_type.toUpperCase()} {play.location_details}
              </p>
            </div>
            {!play.adhoc && (
              <div>
                <p className="font-semibold text-slate-300">Pax:</p>
                <p className="text-slate-300 font-mono">
                  <span className="text-red-700">{play.observed_value}</span>
                  <span className="text-slate-600 opacity-70">
                    {" "}
                    (&gt; {play.threshold_value})
                  </span>
                </p>
              </div>
            )}
            <div>
              <p className="font-semibold">Time:</p>
              <p className="text-slate-300">
                {play.start_interval === play.end_interval ? (
                  formatInterval(play.start_interval)
                ) : (
                  <>
                    {formatInterval(play.start_interval)} to{" "}
                    {formatInterval(play.end_interval)}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </>
  );
}
