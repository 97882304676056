import { useParams } from "react-router-dom";
import { useSessionData } from "@/stores/SessionDataContext";
import { TerminalSetup } from "@/pages/data/terminal/terminal-utils";
import { AirportElement } from "@/lib/types";

export function useConcourse(): AirportElement | null {
  const { concourse: concourseName } = useParams<{ concourse: string }>();

  const { airport: airportSetup } = useSessionData();

  if (!concourseName || !airportSetup) {
    return null;
  }

  // Iterate through all terminals to find the matching concourse
  for (const terminal of airportSetup.terminal) {
    const foundConcourse = terminal.concourses.find(
      (concourse) =>
        concourse.name.toLowerCase() === concourseName.toLowerCase(),
    );

    if (foundConcourse) {
      return foundConcourse;
    }
  }

  return null;
}
