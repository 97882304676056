import React, { FunctionComponent } from "react";
import { ExploreConfig } from "@/types/explore";
import { useReportContext } from "@/stores/ReportContext";
import { getZoneData } from "@/queries/report";
import { useSessionData } from "@/stores/SessionDataContext";
import { useExplore } from "@/components/explore/ExploreContext";

export const ConcernView: FunctionComponent = () => {
  // const { reportId } = useReportContext();
  // const { data: zoneData, isLoading: zoneDataLoading } = getZoneData(
  //   config,
  //   reportId,
  // );
  // const { airport_config } = useSessionData();
  //
  // if (zoneDataLoading) return null;
  // const concerns = zoneData.concerns;
  // if (!concerns || concerns.length === 0) return null;

  const { concerns } = useExplore();

  return (
    <div
      className={
        "prose dark:prose-invert prose-sm mt-6 mb-12 !w-full !max-w-full"
      }
    >
      {/*<h1>Recommendations</h1>*/}
      {/*{concerns.map((c) => {*/}
      {/*  return (*/}
      {/*    <div key={c.zone_index + "-" + c.time_index + "-" + c.value}>*/}
      {/*      <pre className={"whitespace-pre-wrap"}>{c.title}</pre>*/}
      {/*      <div className="dark:bg-gray-900">*/}
      {/*        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">*/}
      {/*          <thead className="bg-gray-50 dark:bg-gray-800">*/}
      {/*            <tr>*/}
      {/*              <th*/}
      {/*                scope="col"*/}
      {/*                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"*/}
      {/*              >*/}
      {/*                Location*/}
      {/*              </th>*/}
      {/*              <th*/}
      {/*                scope="col"*/}
      {/*                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"*/}
      {/*              >*/}
      {/*                Time*/}
      {/*              </th>*/}
      {/*              <th*/}
      {/*                scope="col"*/}
      {/*                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"*/}
      {/*              >*/}
      {/*                Threshold*/}
      {/*              </th>*/}
      {/*              <th*/}
      {/*                scope="col"*/}
      {/*                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"*/}
      {/*              >*/}
      {/*                Observed*/}
      {/*              </th>*/}
      {/*            </tr>*/}
      {/*          </thead>*/}
      {/*          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-600">*/}
      {/*            <tr>*/}
      {/*              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">*/}
      {/*                {c.location}*/}
      {/*              </td>*/}
      {/*              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">*/}
      {/*                {c.time}*/}
      {/*              </td>*/}
      {/*              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">*/}
      {/*                {c.threshold}*/}
      {/*              </td>*/}
      {/*              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-red-600">*/}
      {/*                {c.value}*/}
      {/*              </td>*/}
      {/*            </tr>*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  );*/}
      {/*})}*/}
    </div>
  );
};
