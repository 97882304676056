import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { resetPassword } from "@/queries/user";
import Cookies from "js-cookie";

interface LocationState {
  from: {
    pathname: string;
  };
}

function ResetPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );
  const { mutateAsync: doResetPassword } = resetPassword();

  const handleDoReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(undefined);

    try {
      // const response = await myaxios.post("/auth/login", {
      //   confirm,
      //   password,
      // });
      const { success, token, message } = await doResetPassword({
        password,
        confirm,
      });

      if (success) {
        // Redirect to "/" and force a full page reload
        window.location.href = "/";
        window.location.reload();
      } else if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again later.");
    }
  };

  const handleLogout = () => {
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }

    navigate("/login");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="absolute top-0 right-0 m-4">
        <Button onClick={handleLogout}>Log out</Button>
      </div>

      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl">Set a new password</CardTitle>
          <CardDescription>
            Please set a new password to continue.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {errorMessage && (
            <div className="opacity-70 dark:bg-orange-900 border dark:border-orange-600 rounded px-4 py-2 mb-4 text-sm">
              {errorMessage}
            </div>
          )}
          <div className="space-y-2">
            <Label htmlFor="username">Password</Label>
            <Input
              id="password"
              placeholder=""
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Confirm Password</Label>
            <Input
              id="confirm"
              required
              type="password"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full" onClick={handleDoReset}>
            Continue
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ResetPage;
