import { groupBy, capitalize, sumBy, uniq } from "lodash";
import Highcharts from "highcharts";
import { DonutChart, MultiSeriesChart, Series } from "@/pages/data/DataChart";
import React from "react";
import HighchartsMore from "highcharts/highcharts-more";
import Variablepie from "highcharts/modules/variable-pie";
import { Play } from "@/queries/plays";

if (typeof Highcharts === "object" && typeof window !== "undefined") {
  HighchartsMore(Highcharts);
  Variablepie(Highcharts);
  // Stock(Highcharts);
  // NoDataToDisplay(Highcharts);
  // SolidGauge(Highcharts);
  // Heatmap(Highcharts);
}

export function PlaylistReport({ plays }: { plays: Play[] }) {
  // Helper function to determine play status
  const getPlayStatus = (play: Play): string => {
    if (play.completed_at) {
      return "Completed";
    }
    if (play.actioned_at) return "Actioned";
    if (play.scheduled_at) return "Scheduled";
    return "Pending";
  };

  // Chart 1: Play status summary (unchanged)
  const prepareStatusLocationChart = () => {
    const statuses = ["Pending", "Scheduled", "Actioned", "Completed"];
    const statusGroups = groupBy(plays, getPlayStatus);
    const locationTypes = uniq(plays.map((p) => p.location_type));

    const series: Series[] = locationTypes.map((locType) => ({
      name: capitalize(locType.toLowerCase()),
      // Ensure we map through all possible statuses
      values: statuses.map(
        (status) =>
          (statusGroups[status] || []).filter(
            (p) => p.location_type === locType,
          ).length,
      ),
    }));

    return {
      series,
      labels: statuses,
      xAxisTitle: "Status",
      yAxisTitle: "Number of Plays",
    };
  };

  // Chart 2: Play Status Distribution (corrected labels)
  const prepareStatusDonut = () => {
    const statusCounts = groupBy(plays, getPlayStatus);
    const statuses = ["Pending", "Scheduled", "Actioned", "Completed"];

    const values = statuses.map(
      (status) => (statusCounts[status] || []).length,
    );
    const labels = statuses; // Now using the status names as labels

    return {
      values,
      labels,
      centerLabel: plays.length.toString(),
      centerLabelSubText: "Pain Points",
    };
  };

  // Chart 3: Passenger Impact (corrected labels)
  const preparePassengerImpact = () => {
    const playTypeGroups = groupBy(plays, "title");

    const values = Object.values(playTypeGroups).map((group) => group.length);
    const labels = Object.keys(playTypeGroups); // Now using play titles as labels
    const radii = Object.values(playTypeGroups).map((group) =>
      sumBy(group, "observed_value"),
    );

    return {
      values,
      labels,
      radii,
    };
  };

  const statusLocationData = prepareStatusLocationChart();
  const statusDonutData = prepareStatusDonut();
  const passengerImpactData = preparePassengerImpact();

  return (
    <div className="space-y-8 p-6">
      {/* Chart 1: Status Summary */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Play Status Summary</h2>
        <MultiSeriesChart
          {...statusLocationData}
          chartType="column"
          stacked={true}
          height={300}
        />
      </div>

      {/* Chart 2: Status Distribution */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Play Status Distribution</h2>
        <DonutChart {...statusDonutData} height={300} />
      </div>

      {/* Chart 3: Passenger Impact */}
      <div>
        <h2 className="text-xl font-semibold mb-4">
          Passenger Impact by Play Type
        </h2>
        <DonutChart {...passengerImpactData} height={300} />
      </div>
    </div>
  );
}
