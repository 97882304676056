import { useDataPage } from "@/pages/data/DataPageContext";
import { getAggregateData, getReportComponentData } from "@/queries/report";
import { useReportContext } from "@/stores/ReportContext";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { buildSingleSeries } from "@/viz/chart-data";
import React, { useEffect } from "react";
import {
  Card,
  MultiAxisChart,
  MultiSeriesChart,
  PercentageChart,
} from "@/pages/data/DataChart";
import { usePage } from "@/components/page/PageContext";
import { useSessionData } from "@/stores/SessionDataContext";

function MeasureByTerminal({ cardTitle, measure, xAxisLabel }) {
  const { reportId } = useReportContext();
  const { terminals } = useSessionData();
  let terminalNames = [];
  const { data, isLoading, isSuccess } = getAggregateData(
    terminals.map((t) => {
      terminalNames.push(t.terminal_name);
      return {
        query: {
          measure,
          terminal_id: t.id,
          type: "terminal",
        },
      };
    }),
    reportId,
  );

  if (!isSuccess || data.length === 0) {
    return null;
  }
  return (
    <Card title={cardTitle}>
      <MultiSeriesChart
        series={data.map((d, i) => ({
          name: "Terminal " + terminalNames[i],
          values: d.values,
        }))}
        labels={data[0].labels}
        xAxisTitle={"Time"}
        yAxisTitle={xAxisLabel}
        totalRequired={true}
        chartType="column"
      />
    </Card>
  );
}

export default function TerminalOverviewPage() {
  const { setTitles } = usePage();
  useEffect(() => {
    setTitles(["Terminal Overview"]);
  }, []);
  return (
    <div>
      <div className="w-full h-90">
        <MeasureByTerminal
          measure={"pax"}
          cardTitle="Total Passengers By Terminal"
          xAxisLabel="Passengers"
        />
      </div>
      <div className="w-full h-90">
        <MeasureByTerminal
          measure={"flight"}
          cardTitle="Total Flights By Terminal"
          xAxisLabel="Flights"
        />
      </div>
      <div className="w-full h-90">
        <MeasureByTerminal
          measure={"bag"}
          cardTitle="Total Bags By Terminal"
          xAxisLabel="Bags"
        />
      </div>
    </div>
  );
}
