import React, { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { CommentForm } from "@/components/playlist/detail/Common";
import {
  Action,
  getActionsForPlay,
  Play,
  useCompleteAction,
} from "@/queries/plays";

interface ActionItemProps {
  action: Action;
  onComplete: (actionId: number, comment: string) => void;
  readOnly?: boolean;
}

function ActionItem({ action, onComplete, readOnly }: ActionItemProps) {
  const [showCommentForm, setShowCommentForm] = useState(false);

  return (
    <li className="space-y-2">
      <div className="flex space-x-2 items-center">
        <Checkbox
          id={`action-${action.id}`}
          checked={!!action.completed_at}
          onCheckedChange={() => !readOnly && setShowCommentForm(true)}
          disabled={!!action.completed_at || readOnly}
          className="border-slate-500"
        />
        <div className="space-y-1">
          <Label
            htmlFor={`action-${action.id}`}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {action.message}
          </Label>
          {action.completed_at && (
            <p className="text-sm text-slate-400">
              Completed by: {action.completed_by?.name} at{" "}
              {new Date(action.completed_at)
                .toLocaleString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  day: "2-digit",
                  month: "2-digit",
                })
                .replace(",", "")}
            </p>
          )}
        </div>
      </div>

      {showCommentForm && !action.completed_at && !readOnly && (
        <CommentForm
          onSuccess={(comment) => {
            onComplete(action.id, comment);
            setShowCommentForm(false);
          }}
          onCancel={() => setShowCommentForm(false)}
          buttonLabel="Execute"
        />
      )}
    </li>
  );
}

export function PlayActions({ play }: { play: Play }) {
  const { isLoading, data: actions } = getActionsForPlay(play.id);
  const { mutate: completeAction } = useCompleteAction(play.id);
  const handleActionComplete = (actionId: number, comment: string) => {
    completeAction({ actionId, config: { comment } });
  };

  if (isLoading) {
    return <Skeleton className="w-full h-32 bg-slate-700" />;
  }

  if (!actions?.length) {
    return <p className="text-slate-300">No actions found for this play.</p>;
  }

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Execute</h3>
      <ul className="space-y-4">
        {actions.map((action) => (
          <ActionItem
            key={action.id}
            action={action}
            onComplete={handleActionComplete}
            readOnly={play.read_only}
          />
        ))}
      </ul>
    </div>
  );
}
