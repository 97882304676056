import React from "react";
import ReactDOM from "react-dom/client";
import "./main.css";
import "./app.css";
import "./style/highcharts.css";
import App from "@/App";
import * as Sentry from "@sentry/react";
import { SENTRY_ENABLED } from "@/lib/constants";

import Highcharts from "highcharts";
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: "https://347eb3e41526ffab6af6f0221297e116@o4506725854019584.ingest.sentry.io/4506725869420544",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/app\.raidohub\.com\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
