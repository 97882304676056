export interface IMetricDisplayProps {
  name: string;
  value: number;
  sizeClassName?: string; // Optional prop for size class
}

export function MetricDisplay({
  name,
  value,
  sizeClassName = "w-1/3 h-36",
}: IMetricDisplayProps) {
  return (
    <div
      className={`${sizeClassName} bg-slate-900 border border-slate-600 hover:bg-slate-700 hover:border-slate-400 hover:shadow-lg dark:bg-slate-900 dark:border-slate-600 dark:hover:bg-slate-700 dark:hover:border-slate-400 transition duration-300 ease-in-out`}
    >
      <div className="uppercase text-center mt-4 text-amber-500 text-lg dark:text-amber-500">
        {name}
      </div>
      <div className="text-center mt-4 text-4xl text-slate-200 dark:text-slate-200">
        {(value ?? 0).toLocaleString("en-US")}
      </div>
    </div>
  );
}
