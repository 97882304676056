import { Play } from "@/queries/plays";
import { Armchair, ChevronRight, DoorClosed, Utensils } from "lucide-react";


const formatInterval = (interval: number) => {
    const hours = Math.floor(interval / 6);
    const minutes = (interval % 6) * 10;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};

function humanizeTimestamp(interval: number): string {
    // Convert interval to minutes (each interval is 10 minutes)
    const minutes = interval * 10;

    // Get current time in minutes since midnight
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    // Convert interval to time
    const intervalHours = Math.floor(minutes / 60);
    const intervalMinutes = minutes % 60;

    // Create target time in minutes
    const targetMinutes = intervalHours * 60 + intervalMinutes;

    // Calculate difference in minutes
    const diffMinutes = targetMinutes - currentMinutes;

    // Convert difference to absolute hours and minutes
    const absHours = Math.abs(Math.floor(diffMinutes / 60));
    const absMinutes = Math.abs(diffMinutes % 60);

    // Format the output
    if (diffMinutes === 0) {
        return "now";
    } else if (diffMinutes > 0) {
        // Future time
        if (absHours >= 1) {
            return `${absHours}h`;
        } else {
            return `${absMinutes}m`;
        }
    } else {
        // Past time
        if (absHours >= 1) {
            return `-${absHours}h`;
        } else {
            return `-${absMinutes}m`;
        }
    }
}

const StatusIndicator = ({
    label,
    timestamp,
}: {
    label: string;
    timestamp: string | null;
}) => (
    <div className="flex items-center space-x-1">
        <div
            className={`w-3 h-3 rounded-full border ${timestamp ? "bg-green-500" : "bg-gray-300"}`}
        />
        <span className="text-xs font-mono">{label}</span>
    </div>
);

interface PlayItemProps {
    play: Play;
    isSelected: boolean;
    onClick: () => void;
}

const getLocationIcon = (locationType: string) => {
    return null;
    switch (locationType) {
        case "gate":
            return <Armchair className="w-4 h-4 mr-1" />;
        case "restroom":
            return <Utensils className="w-4 h-4 mr-1" />;
        default:
            return <DoorClosed className="w-4 h-4 mr-1" />; // Default icon if no match
    }
};

export function PlayItem({ play, isSelected, onClick }: PlayItemProps) {
    return (
        <div
            onClick={onClick}
            className={`mb-4 p-4 rounded-lg shadow cursor-pointer flex transition-colors ${isSelected ? "bg-slate-700" : "bg-slate-900 hover:bg-slate-700"
                }`}
        >
            <div className="pr-4 pt-6 font-mono text-slate-300 opacity-80">
                <div>{formatInterval(play.start_interval)}</div>
                <div className="opacity-50">
                    {humanizeTimestamp(play.start_interval)}
                </div>
            </div>
            <div className="flex-grow">
                <div className="flex justify-between items-center mb-2">
                    <div className="text-lg font-semibold flex-grow">{play.title}</div>
                    <div className="uppercase text-sm border-slate-600 opacity-70 py-1 px-2 rounded border mr-2">
                        {play.category}
                    </div>
                    <ChevronRight className="w-4 h-4" />
                </div>
                <div className="flex items-center">
                    <div className="p-1 border-indigo-900 bg-indigo-500 rounded opacity-70 text-sm uppercase flex items-center">
                        {getLocationIcon(play.location_type)}{" "}
                        <span>
                            {play.location_type === "other" ? "" : play.location_type}{" "}
                            {play.location_details}
                        </span>
                    </div>
                    {!play.adhoc && (
                        <div className="text-slate-300 font-mono ml-4">
                            <span className="text-red-500">{play.observed_value} pax</span>{" "}
                            <span className="opacity-30">(&gt; {play.threshold_value})</span>
                        </div>
                    )}
                </div>
                <div className="flex space-x-4 mt-4">
                    <StatusIndicator label="Schedule" timestamp={play.scheduled_at} />
                    <StatusIndicator label="Execute" timestamp={play.actioned_at} />
                    <StatusIndicator label="Complete" timestamp={play.completed_at} />
                </div>
            </div>
        </div>
    );
}
