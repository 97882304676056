import {useTerminal} from "@/pages/data/terminal/terminal-utils";
import {usePage} from "@/components/page/PageContext";
import {useEffect} from "react";
import {useReportContext} from "@/stores/ReportContext";
import {getPlays} from "@/queries/plays";
import {PlayListDisplay} from "@/components/playlist/PlayListDisplay";

export function FullPlaylistPage() {
  const { setTitles } = usePage();
  useEffect(() => {
    setTitles(["All Pain Points"]);
  }, []);
  const { reportId } = useReportContext();

  const { data, isSuccess } = getPlays(reportId, {
  });

  return <div>{isSuccess && <PlayListDisplay plays={data.plays} />}</div>;
}
