import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

type Titles = string[];

interface PageContextType {
  titles: Titles;
  setTitles: (newTitles: Titles) => void;
}

const PageContext = createContext<PageContextType | undefined>(undefined);

interface PageProviderProps {
  children: ReactNode;
}

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const [titles, setTitlesState] = useState<Titles>([]);

  const setTitles = (newTitles: Titles) => {
    setTitlesState(newTitles);
  };

  // Update window title when titles change
  useEffect(() => {
    if (titles.length > 0) {
      document.title = titles[titles.length - 1] + " | RaidoHub";
    }
  }, [titles]);

  return (
    <PageContext.Provider value={{ titles, setTitles }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePage = (): PageContextType => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePage must be used within a PageProvider");
  }
  return context;
};
