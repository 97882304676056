import { FunctionComponent } from "react";
import { useReportContext } from "@/stores/ReportContext";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export const ReportForceBar: FunctionComponent = () => {
  const { forced, formattedDate, report } = useReportContext();
  const navigate = useNavigate();

  if (!forced) return null;

  return (
    <div
      className={
        "dark:bg-amber-900 w-full py-3 mx-auto md:px-6 lg:px-8 flex items-center"
      }
    >
      <div>
        Viewing {report.type === "future" ? "forecasted" : "archived"} data for:{" "}
        {formattedDate}
      </div>
      <div className={"ml-4"}>
        <Button variant={"secondary"} onClick={() => navigate("/")}>
          View Current Data
        </Button>
      </div>
    </div>
  );
};
