import {
  useTerminal,
  useTerminalId,
} from "@/pages/data/terminal/terminal-utils";
import { useReportContext } from "@/stores/ReportContext";
import { getAggregateData, ZoneQuery } from "@/queries/report";
import { Card, MultiSeriesChart } from "@/pages/data/DataChart";
import {
  ChartIntlVsDomestic,
  TrafficChart,
} from "@/pages/data/terminal/TerminalCharts";
import { usePage } from "@/components/page/PageContext";
import { useEffect } from "react";

export function PassengerFlowPage() {
  const terminal = useTerminal();
  const { reportId } = useReportContext();

  const { setTitles } = usePage();

  useEffect(() => {
    setTitles(["Passenger Flow"]);
  }, []);

  return (
    <div className={"w-full grid grid-cols-4"}>
      <div className="col-span-2">
        <ChartIntlVsDomestic
          query={{
            terminal_id: terminal.id,
            direction: "dep",
            measure: "pax",
            type: "terminal",
            group: "terminal",
          }}
          title={
            "Departing Passenger Flow Comparison International vs Domestic"
          }
        />
      </div>
      <div className="col-span-2">
        <ChartIntlVsDomestic
          query={{
            terminal_id: terminal.id,
            direction: "arr",
            measure: "pax",
            type: "terminal",
            group: "terminal",
          }}
          title={"Arriving Passenger Flow Comparison International vs Domestic"}
        />
      </div>
      <div className="col-span-3">
        <Card title={"Connecting Passenger Count\n"}>
          <TrafficChart
            query={{
              terminal_id: terminal.id,
              measure: "pax",
              type: "terminal",
              pax_type: "connecting",
            }}
          />
        </Card>
      </div>
    </div>
  );
}
