import React, { useState } from "react";
import RetailSummary from "@/pages/retail/RetailSummary";
import StoreList from "@/pages/retail/StoreList";
import { useMediaQuery } from "react-responsive";
import { useSessionData } from "@/stores/SessionDataContext";
import { useParams } from "react-router-dom";

function RetailHome() {
  const { restrict_store } = useSessionData();
  const singleStore = !!restrict_store;
  const { store } = useParams();

  const storeId = store ? parseInt(store) : null;
  const stores = singleStore ? [] : storeId ? [storeId] : [];

  return (
    <div>
      {!singleStore && <StoreList />}
      <div className={"w-full h-full  px-8 py-4"}>
        <RetailSummary stores={stores} />
      </div>
    </div>
  );
}

export default RetailHome;
