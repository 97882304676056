import { createContext, useContext } from "react";
import { components } from "@/lib/openapi";

export type ZoneData = components["schemas"]["ZoneData"];
export type Concern = components["schemas"]["ZoneConcern"];

interface IExploreContext {
  zones: ZoneData[];
  times: string[];
  concerns: Concern[];
}

export const ExploreContext = createContext<IExploreContext | undefined>(
  undefined,
);

export const useExplore = () => {
  const context = useContext(ExploreContext);
  if (context === undefined) {
    throw new Error("useExplore must be used within a ExploreProvider");
  }
  return context;
};
