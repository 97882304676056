import React, { FunctionComponent, useEffect, useState } from "react";
import { z } from "zod";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import {
  getAllRules,
  createRule,
  updateRule,
  deleteRule,
} from "@/queries/playlists";
import { usePage } from "@/components/page/PageContext";
import { components } from "@/lib/openapi";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { useSessionData } from "@/stores/SessionDataContext";

type Rule = components["schemas"]["RuleSchema"];

const formSchema = z.object({
  title: z.string().min(1).max(255),
  category: z.string().min(1).max(255),
  location_type: z.string().min(1, "Location type is required"),
  condition_type: z.string().min(1),
  terminal: z.string().nullable(),
  concourse: z.string().nullable(),
  location_filter: z.string().nullable(),
  threshold: z.string().min(1),
  actions: z.array(z.string().min(1)),
  enabled: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

const RuleForm: FunctionComponent = () => {
  const { airport_config } = useSessionData();
  const { record, mode } = useCrudContext<Rule>();
  const createMode = mode === FormMode.Add;
  const [showTerminal, setShowTerminal] = useState(false);
  const [showConcourse, setShowConcourse] = useState(false);
  const [showLocationFilter, setShowLocationFilter] = useState(false);

  const makeDefaultValues = (rule?: Rule): FormValues => {
    return {
      title: rule?.title ?? "",
      category: rule?.category ?? "General",
      location_type: rule?.location_type ?? "gate",
      condition_type: rule?.condition_type ?? "above_threshold",
      terminal: rule?.terminal ?? "",
      concourse: rule?.concourse ?? "",
      location_filter: rule?.location_filter ?? "",
      threshold: rule?.threshold?.toString() ?? "200",
      actions: rule?.actions ?? [""],
      enabled: rule?.enabled ?? true,
    };
  };

  const form = useForm<FormValues>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const locationTypeFilterMap = {
    gate: ["terminal", "concourse", "location"],
    ticketing: ["terminal", "location"],
    checkpoint: ["terminal", "location"],
    restroom: ["terminal", "concourse", "location"],
    customs: ["location"],
    intl_baggage: ["location"],
    door: ["terminal", "location"],
    terminal: ["location"],
  };

  const currentLocationType = form.watch("location_type");
  const allowedFilters = locationTypeFilterMap[currentLocationType] || [];

  const terminalFilterAvailable =
    allowedFilters.includes("terminal") &&
    !form.watch("concourse") &&
    !form.watch("location_filter");

  const concourseFilterAvailable =
    airport_config.has_concourse &&
    allowedFilters.includes("concourse") &&
    !form.watch("terminal") &&
    !form.watch("location_filter");

  const locationFilterAvailable =
    allowedFilters.includes("location") &&
    !form.watch("terminal") &&
    !form.watch("concourse");

  // @ts-ignore TS2322
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "actions",
  });

  const createMutation = createRule();
  const updateMutation = updateRule(record?.id ?? 0);
  const deleteMutation = deleteRule(record?.id ?? 0);

  const locationTypeOptions = [
    { value: "gate", label: "Gate" },
    { value: "ticketing", label: "Ticketing" },
    { value: "checkpoint", label: "Checkpoint" },
    { value: "restroom", label: "Restroom" },
    { value: "customs", label: "Customs" },
    { value: "intl_baggage", label: "International Baggage" },
    { value: "door", label: "Door" },
    { value: "terminal", label: "Terminal" },
  ];

  const conditionTypeOptions = [
    { value: "above_threshold", label: "Above Threshold" },
    { value: "below_threshold", label: "Below Threshold" },
  ];

  function onDelete() {
    deleteMutation.mutate();
  }

  const onSubmit = (values: FormValues) => {
    const mutationData = {
      ...values,
      threshold: parseInt(values.threshold, 10),
      actions: values.actions.filter((action) => action.trim() !== ""),
      message: "",
    };

    if (createMode) {
      // @ts-ignore
      createMutation.mutate(mutationData);
    } else {
      // @ts-ignore
      updateMutation.mutate(mutationData);
    }
  };

  useEffect(() => {
    const defaultValues = makeDefaultValues(record);
    form.reset(defaultValues, {
      keepDirty: false,
      keepTouched: false
    });
    setShowTerminal(!!defaultValues.terminal);
    setShowConcourse(!!defaultValues.concourse);
    setShowLocationFilter(!!defaultValues.location_filter);
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle="Add Play"
      editTitle="Edit Play"
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <Form {...form}>
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Play Title</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="category"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Play Category</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="location_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Location Type</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select location type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {locationTypeOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="condition_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Condition Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select condition type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {conditionTypeOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-4 mt-4 flex flex-col">
          {!showTerminal && terminalFilterAvailable && (
            <a
              type="button"
              className="underline opacity-70 text-sm cursor-pointer"
              onClick={() => setShowTerminal(!showTerminal)}
            >
              [+] Filter by Terminal
            </a>
          )}

          {!showConcourse && concourseFilterAvailable && (
            <a
              className="underline opacity-70 text-sm cursor-pointer"
              onClick={() => setShowConcourse(!showConcourse)}
            >
              [+] Filter by Concourse
            </a>
          )}

          {!showLocationFilter && locationFilterAvailable && (
            <a
              className="underline opacity-70 text-sm cursor-pointer"
              onClick={() => setShowLocationFilter(!showLocationFilter)}
            >
              [+] Specific Locations
            </a>
          )}
        </div>

        {(showTerminal || form.watch("terminal")) && (
          <FormField
            control={form.control}
            name="terminal"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Terminal</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter Terminal" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {(showConcourse || form.watch("concourse")) && (
          <FormField
            control={form.control}
            name="concourse"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Concourse</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter Concourse" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {(showLocationFilter || form.watch("location_filter")) && (
          <FormField
            control={form.control}
            name="location_filter"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Location Filter</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Enter comma separated names, e.g. A13, A14"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="threshold"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Threshold</FormLabel>
              <FormControl>
                <Input {...field} type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-2">
          <FormLabel>Execute</FormLabel>
          {fields.map((field, index) => (
            <FormItem key={field.id}>
              <FormControl>
                <div className="flex items-center space-x-2">
                  <Textarea
                    {...form.register(`actions.${index}`)}
                    placeholder={`Execute ${index + 1}`}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={() => remove(index)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </FormControl>
            </FormItem>
          ))}
          <Button type="button" variant="outline" onClick={() => append("")}>
            Add Execute
          </Button>
        </div>

        <FormField
          control={form.control}
          name="enabled"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>Enabled</FormLabel>
                <FormDescription>Enable or disable this play</FormDescription>
              </div>
            </FormItem>
          )}
        />
      </Form>
    </CrudForm>
  );
};

const ManageRulesPage: React.FC = () => {
  const { isLoading, data: rules } = getAllRules();

  const { setTitles } = usePage();
  useEffect(() => {
    setTitles(["Manage Plays"]);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Rule>
      title="Plays"
      addButton="Add Play"
      data={rules}
      editForm={<RuleForm />}
      display={(r) => {
        return `${r.title}`;
      }}
    />
  );
};

export default ManageRulesPage;
