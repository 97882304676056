import { components } from "@/lib/openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";

export function getAllRules() {
  return useQuery({
    queryKey: ["playlists"],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/playlist/rules", { signal });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createRule() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["RuleCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/playlist/rule", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
}

export function updateRule(rule_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["RuleSchemaPatch"],
    ) => {
      const { data, error } = await client
        .get()
        .PATCH("/api/playlist/rule/{rule_id}", {
          params: {
            path: {
              rule_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
}

export function deleteRule(rule_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/playlist/rule/{rule_id}", {
          params: {
            path: {
              rule_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
}
