// AppLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderBar2 } from "@/components/page/HeaderBar2";
import { Toaster } from "@/components/ui/toaster";
import { ReportForceBar } from "@/components/page/ReportForceBar";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/sidebar/AppSidebar";
import { HeaderBar3 } from "@/components/page/HeaderBar3";
import { PageProvider } from "@/components/page/PageContext";

interface AppLayoutProps {
  enableForceBar?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({ enableForceBar = true }) => {
  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="w-full">
        {/*<SidebarTrigger />*/}
        <PageProvider>
          <HeaderBar3></HeaderBar3>
          <ReportForceBar />
          <div className="p-4">
            <Outlet />
          </div>
        </PageProvider>
      </main>
      <Toaster />
    </SidebarProvider>
  );
};

export default AppLayout;
