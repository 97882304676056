// AppLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderBar2 } from "@/components/page/HeaderBar2";
import { Toaster } from "@/components/ui/toaster";
import { ReportForceBar } from "@/components/page/ReportForceBar";
import { useDataPage } from "@/pages/data/DataPageContext";

const DataLayout = () => {
  const { title } = useDataPage();
  return <Outlet />;
};

export default DataLayout;
