import { usePage } from "@/components/page/PageContext";
import { useReportContext } from "@/stores/ReportContext";
import React, { useEffect, useState } from "react";
import { Card, ExploreDisplay } from "@/pages/data/DataChart";
import {
  ChartIntlVsDomestic,
  TrafficChart,
} from "@/pages/data/terminal/TerminalCharts";
import { useConcourse } from "@/pages/data/concourse/concourse-utils";
import { Select, SelectTrigger, SelectValue, SelectItem, SelectContent } from "@/components/ui/select";

export function ConcourseGatesPage() {
  const { setTitles } = usePage();
  const { reportId } = useReportContext();
  const concourse = useConcourse();
  const [direction, setDirection] = useState<'dep' | 'arr'>('dep');
  const [passengerType, setPassengerType] = useState<'direct' | 'connecting' | 'both'>('both');

  useEffect(() => {
    setTitles(["Gates Overview"]);
  }, []);

  if (!concourse) {
    return null;
  }

  return (
    <div className="grid grid-cols-4">
      <div className="col-span-4 h-90">
        <Card 
          title="Gate - Passenger Density" 
          innerClassName="max-w-full"
          actions={
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <label className="text-sm text-muted-foreground">Direction</label>
                <Select value={direction} onValueChange={(value) => setDirection(value as 'dep' | 'arr')}>
                  <SelectTrigger className="w-[180px] text-sm">
                    <SelectValue placeholder="Select direction" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="dep">Departure</SelectItem>
                    <SelectItem value="arr">Arrival</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center gap-2">
                <label className="text-sm text-muted-foreground">Passenger Type</label>
                <Select value={passengerType} onValueChange={(value) => setPassengerType(value as 'direct' | 'connecting' | 'both')}>
                  <SelectTrigger className="w-[180px] text-sm">
                    <SelectValue placeholder="Select passenger type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="direct">Direct</SelectItem>
                    <SelectItem value="connecting">Connecting</SelectItem>
                    <SelectItem value="both">Both</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          }
        >
          <ExploreDisplay
            height={850}
            query={{
              concourse_id: concourse.id.toString(),
              measure: "pax",
              direction: direction,
              type: "gate",
              pax_type: passengerType === 'both' ? null : passengerType
            }}
          />
        </Card>
      </div>
    </div>
  );
}

export function ConcoursePassengerFlowPage() {
  const concourse = useConcourse();

  const { setTitles } = usePage();

  useEffect(() => {
    setTitles(["Passenger Flow"]);
  }, []);

  return (
    <div className={"w-full grid grid-cols-4"}>
      <div className="col-span-2">
        <ChartIntlVsDomestic
          query={{
            concourse_id: concourse.id,
            direction: "dep",
            measure: "pax",
            type: "concourse",
            group: "concourse",
          }}
          title={
            "Departing Passenger Flow Comparison International vs Domestic"
          }
        />
      </div>
      <div className="col-span-2">
        <ChartIntlVsDomestic
          query={{
            concourse_id: concourse.id,
            direction: "arr",
            measure: "pax",
            type: "concourse",
            group: "concourse",
          }}
          title={"Arriving Passenger Flow Comparison International vs Domestic"}
        />
      </div>
      <div className="col-span-3">
        <Card title={"Connecting Passenger Count\n"}>
          <TrafficChart
            query={{
              concourse_id: concourse.id,
              measure: "pax",
              pax_type: "connecting",
              type: "concourse",
              group: "concourse",
            }}
          />
        </Card>
      </div>
    </div>
  );
}
