import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { CommentForm } from "@/components/playlist/detail/Common";
import {
  Play,
  useMarkPlayActioned,
  useMarkPlayCompleted,
  useMarkPlayScheduled,
  usePlayComment,
} from "@/queries/plays";

interface PlayStatusProps {
  play: Play;
}

export function PlayStatus({ play }: PlayStatusProps) {
  const [activeStatus, setActiveStatus] = useState<
    "scheduled" | "actioned" | "completed" | "comment" | null
  >(null);

  const { mutate: doMarkScheduled } = useMarkPlayScheduled(play.id);
  const { mutate: doMarkActioned } = useMarkPlayActioned(play.id);
  const { mutate: doMarkCompleted } = useMarkPlayCompleted(play.id);
  const { mutate: doComment } = usePlayComment(play.id);

  const markScheduled = async (comment: string) => {
    doMarkScheduled({
      comment,
    });
    setActiveStatus(null);
  };

  const markActioned = async (comment: string) => {
    doMarkActioned({
      comment,
    });
    setActiveStatus(null);
  };

  const markCompleted = async (comment: string) => {
    doMarkCompleted({
      comment,
    });
    setActiveStatus(null);
  };

  const leaveComment = async (comment: string) => {
    doComment({
      comment,
    });
    setActiveStatus(null);
  };

  // If play is read-only, don't render any buttons
  if (play.read_only) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        {!play.scheduled_at && (
          <Button
            variant={
              activeStatus === "scheduled"
                ? "outline"
                : !play.actioned_at && !play.completed_at
                  ? "default"
                  : "secondary"
            }
            onClick={() => setActiveStatus("scheduled")}
          >
            Schedule
          </Button>
        )}

        {!play.actioned_at && (
          <Button
            variant={
              activeStatus === "actioned"
                ? "outline"
                : play.scheduled_at && !play.completed_at
                  ? "default"
                  : "secondary"
            }
            onClick={() => setActiveStatus("actioned")}
          >
            Execute
          </Button>
        )}

        {!play.completed_at && (
          <Button
            variant={
              activeStatus === "completed"
                ? "outline"
                : play.actioned_at
                  ? "default"
                  : "secondary"
            }
            onClick={() => setActiveStatus("completed")}
          >
            Complete
          </Button>
        )}

        <Button
          variant={activeStatus === "comment" ? "outline" : "secondary"}
          onClick={() => setActiveStatus("comment")}
        >
          Comment
        </Button>
      </div>

      {activeStatus && (
        <CommentForm
          onSuccess={(comment) => {
            switch (activeStatus) {
              case "scheduled":
                markScheduled(comment);
                break;
              case "actioned":
                markActioned(comment);
                break;
              case "completed":
                markCompleted(comment);
                break;
              case "comment":
                leaveComment(comment);
                break;
            }
          }}
          buttonLabel={
            {
              scheduled: "Schedule",
              actioned: "Execute",
              completed: "Complete",
              comment: "Comment",
              unknown: "Submit",
            }[activeStatus || "unknown"]
          }
          onCancel={() => setActiveStatus(null)}
        />
      )}
    </div>
  );
}
