import { useMutation, useQuery } from "@tanstack/react-query";
import { client, noAuthClient } from "@/queries/client";
import axios from "axios";

export function getUser({ enabled = true }: { enabled?: boolean } = {}) {
  return useQuery({
    queryKey: ["me"],
    enabled,
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/user/me", {
        signal,
      });
      if (data) return data;
      throw new Error(error);
    },
  });
}

export function switchSession() {
  return useMutation({
    mutationFn: async (airport: number) => {
      const { data, error } = await client.get().POST("/api/session/new/{id}", {
        params: {
          path: {
            id: airport,
          },
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function resetPassword() {
  return useMutation({
    mutationFn: async ({
      password,
      confirm,
    }: {
      password: string;
      confirm: string;
    }) => {
      const { data, error } = await client.get().POST("/api/user/reset", {
        body: {
          confirm,
          password,
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

interface ForgotPasswordData {
  email: string;
}

export function useForgotPassword() {
  return useMutation({
    mutationFn: async ({ email }: ForgotPasswordData) => {
      const { data, error } = await noAuthClient.POST("/api/auth/forgot", {
        body: {
          email: email,
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

interface NewPasswordData {
  reset_token: string;
  password: string;
  confirm: string;
}

export function useNewPassword() {
  return useMutation({
    mutationFn: async (body: NewPasswordData) => {
      const { data, error } = await noAuthClient.POST(
        "/api/auth/new-password",
        {
          body,
        },
      );
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}
